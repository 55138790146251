<template>
  <div class="modal fade" :id="id" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal-google-campaign-detailsLabel">Campaign Attributes</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-if="loading"
               class="d-flex items-center justify-content-center w-100 py-5">
            <div class="spinner-border spinner-border-sm mr-2"></div>
            {{ loadingMessage }}
          </div>
          <div class="statbox widget box p-0" v-else-if="!loading && campaign">
            <div class="widget-content widget-content-area p-0 box-shadow-none">
              <div class="table-responsive">
                <table class="table table-bordered mb-0">
                  <tbody>
                  <tr>
                    <th>Google Ads Account ID</th>
                    <td>{{ campaign.customerId }}</td>
                    <td rowspan="7"
                        class="text-center"
                        style="width: 20%">
                      <img style="height: 200px"
                           :src="amazonAd && amazonAd.product_image ? amazonAd.product_image : '/images/no-image1.png'">
                    </td>
                  </tr>
                  <tr>
                    <th>Campaign Name</th>
                    <td>{{ campaign.name }}</td>
                  </tr>
                  <tr>
                    <th>Nick Name</th>
                    <td>
                      <div class="row" v-if="!editNickName">
                        <div class="col-md-10">
                          {{ campaign.nickname }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editNickName = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input class="form-control form-control-sm"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter Nick Name"
                                   v-model="nickNameForm.name">
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editNickName = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveNickname">
                            <i class="fas fa-save"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Campaign ID</th>
                    <td>{{ campaign.id }}</td>
                  </tr>
                  <tr>
                    <th>Created At</th>
                    <td>{{ campaign.created_at }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Start Date</th>
                    <td>{{ campaign.start_date }}</td>
                  </tr>
                  <tr>
                    <th>Campaign Status</th>
                    <td>{{ campaign.status }}</td>
                  </tr>
                  <tr>
                    <th width="30%">Budget</th>
                    <td colspan="2">
                      <div class="row" v-if="!editBudget">
                        <div class="col-md-10">
                          ${{ campaign.budget.toFixed(2) }} daily average (${{ campaign.monthly_max_budget.toFixed(2) }} monthly max)
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editBudget = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter New Daily Budget"
                                   v-model="newBudgetForm.amount">
                            <v-errors :errors="errorFor('amount')" />
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editBudget = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveBudget">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th width="30%">Maximum Cost-Per-Click Bid</th>
                    <td colspan="2">
                      <div class="row" v-if="!editMaxCpc">
                        <div class="col-md-10">
                          <span v-if="campaign.max_cpc">${{ campaign.max_cpc.toFixed(2) }}</span>
                          <span v-else>No maximum</span>
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editMaxCpc = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('amount')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter Max CPC"
                                   v-model="newMaxCpcForm.amount">
                            <v-errors :errors="errorFor('amount')" />
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editMaxCpc = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveMaxCpc">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Campaign Serving Status</th>
                    <td colspan="2">{{ campaign.serving_status }}</td>
                  </tr>
                  <tr>
                    <th>Ad Serving Optimization Status</th>
                    <td colspan="2">{{ campaign.ad_serving_optimization_status }}</td>
                  </tr>
                  <tr>
                    <th>Bidding Strategy</th>
                    <td colspan="2">
                      <div class="row" v-if="!editBiddingStrategy">
                        <div class="col-md-10">
                          {{ campaign.bidding_strategy }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editBiddingStrategy = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-3">
                          <div class="form-group mb-0">
                            <select class="form-control form-control-sm"
                                    :disabled="loading"
                                    v-model="biddingStrategyForm.strategy">
                              <option value="Target Spend">Target Spend</option>
                              <option value="Manual CPC">Manual CPC</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group mb-0" v-if="biddingStrategyForm.strategy === 'Manual CPC'">
                            <select class="form-control form-control-sm"
                                    :disabled="loading"
                                    v-model="biddingStrategyForm.max_cpc_type">
                              <option value="current" v-if="campaign.max_cpc">
                                Use Current Max CPC ({{ campaign.max_cpc ? ('$' + campaign.max_cpc) : 'No maximum'}})
                              </option>
                              <option value="average">Use average CPC for each keyword</option>
                              <option value="manual">Manual</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group mb-0"
                               v-if="biddingStrategyForm.strategy === 'Manual CPC' && biddingStrategyForm.max_cpc_type === 'manual'">
                            <input type="text"
                                   v-model="biddingStrategyForm.max_cpc"
                                   placeholder="Max CPC"
                                   class="form-control form-control-sm">
                          </div>
                        </div>
                        <div class="col-md-3 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editBiddingStrategy = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveBiddingStrategyBtnHandler">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Location</th>
                    <td colspan="2">
                      <div class="row" v-if="!editLocation">
                        <div class="col-md-10">
                          {{ campaign.criteria_id === '' ? 'All Countries' : (countries.find(c => c.criteria_id == campaign.criteria_id) ? countries.find(c => c.criteria_id == campaign.criteria_id).name : '') }}
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editLocation = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <select class="form-control form-control-sm"
                                    :disabled="loading"
                                    v-model="newLocationForm.criteriaId">
                              <option value="">All Countries</option>
                              <option :value="country.criteria_id"
                                      v-for="country in countries"
                                      :key="'country_'+country.id">{{ country.name }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editLocation = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveLocation">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Target URL for Ad</th>
                    <!--                    <td>{{ campaign.target_url }}</td>-->
                    <td colspan="2">
                      <div class="row" v-if="!editAdLink">
                        <div class="col-md-10">
                          {{ campaign.ad_link }} <br>

                          <div class="pt-2" v-if="!campaign.tracking_url && !(campaign.ad_link.includes('aa_creativeid'))">
                            <span class="text-muted">This link isn’t tracking Google keywords information.</span>
                            <a class="text-info"
                               @click.prevent="changeMassUrl"
                               href="#"> Change to a tracking link?</a>
                          </div>

                          <div v-else>
                            <span class="text-success">Amazon is tracking Google keywords information.</span>
                          </div>
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editAdLink = true">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <div class="form-group mb-0">
                            <input :class="['form-control form-control-sm', {'is-invalid': errorFor('adLink')}]"
                                   :disabled="loading"
                                   type="text"
                                   placeholder="Enter Ad Link"
                                   v-model="adLinkForm.adLink">
                            <v-errors :errors="errorFor('adLink')" />
                          </div>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editAdLink = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveAdLink">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>Tracking Keywords</th>
                    <td v-if="amazonAd" colspan="2">
                      <template v-for="keyword in amazonAd.keywords"
                                :key="'keyword_'+keyword.keyword">
                         <span class="badge badge-primary mb-1 mr-1"
                               v-if="keyword.is_checking">{{ keyword.keyword }}</span>
                      </template>
                    </td>
                    <td v-else colspan="2"></td>
                  </tr>
                  <tr>
                    <th>Labels</th>
                    <td v-if="amazonAd" colspan="2">
                      <div class="row" v-if="!editLabel">
                        <div class="col-md-10">
                           <span class="badge badge-primary mb-1 mr-1"
                                 :style="{backgroundColor: label.color}"
                                 v-for="label in amazonAd.labels"
                                 :key="'label_'+label.id">{{ label.name }}</span>
                        </div>
                        <div class="col-md-2 text-right">
                          <a href="#" @click.prevent="editLabelHandler">
                            <i class="fas fa-edit"></i>
                          </a>
                        </div>
                      </div>
                      <div class="row" v-else>
                        <div class="col-md-8">
                          <select class="select2 select-labels"
                                  id="google-labels"
                                  style="width: 100%"
                                  multiple="multiple"
                                  v-model="labelForm.labels"
                                  data-placeholder="Select label or enter new...">
                            <option :value="label.name"
                                    v-for="label in labels"
                                    :key="'label_'+label.id">{{ label.name }}</option>
                          </select>
                        </div>
                        <div class="col-md-4 text-right">
                          <button v-if="!loading"
                                  class="btn btn-sm btn-dark mr-1"
                                  @click="editLabel = false">
                            <i class="fas fa-times"></i>
                          </button>

                          <button class="btn btn-sm btn-success"
                                  :disabled="loading"
                                  @click="saveLabels">
                            <i class="fas fa-save"></i>
                          </button>

                        </div>
                      </div>
                    </td>
                    <td v-else colspan="2"></td>
                  </tr>
                  <tr v-if="campaign.reasons.length">
                    <th>Google Ad Status</th>
                    <td colspan="2">
                      <b class="text-danger">Disapproved</b>
                      <ul>
                        <li v-for="(rsn, i) in campaign.reasons"
                            :key="'reason_'+i">{{ rsn }}</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-else class="text-center py-5">
            <h2>No data found</h2>
          </div>
        </div><!--end modal-body-->
        <!--        <div class="modal-footer">-->
        <!--          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
  <change-bidding-strategy-modal @saveBidding="saveBidding"
                                 :id="id"
                                 ref="changeBiddingStrategyModal" />
</template>

<script>
import {mapGetters} from "vuex";
import ChangeBiddingStrategyModal from "@/views/modals/ChangeBiddingStrategyModal.vue";

export default {
  props: ['id'],
  components: {ChangeBiddingStrategyModal},
  data() {
    return {
      labels: [],
      amazonAd: null,
      loading: true,
      loadingMessage: '',
      labelForm: {
        amazon_ad_id: '',
        labels: []
      },
      biddingStrategyForm: {
        strategy: 'Manual CPC',
        campaignId: '',
        max_cpc: '',
        max_cpc_type: 'average'
      },
      adLinkForm: {
        adLink: '',
        campaignId: ''
      },
      newLocationForm: {
        criteriaId: '',
        campaignId: ''
      },
      newBudgetForm: {
        amount: '',
        campaignId: ''
      },
      newMaxCpcForm: {
        amount: '',
        campaignId: ''
      },
      nickNameForm: {
        id: '',
        name: ''
      },
      modal: null,
      editLabel: false,
      editNickName: false,
      editBudget: false,
      editLocation: false,
      editMaxCpc: false,
      editAdLink: false,
      editBiddingStrategy: false,
      campaign: null
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      settings: 'settings/getSettings'
    })
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById(this.id));

    setTimeout(() => {
      window.$('#modal-google-campaign-details').on('hide.bs.modal', () => {
        if (window.$('.select-labels').hasClass("select2-hidden-accessible")) {
          window.$('.select-labels').select2('destroy');
          window.$('.select-labels').off('change');
          this.editLabel = false;
        }
      })
    }, 100)

    window.$.fn.modal.Constructor.prototype._enforceFocus = function() {};
  },
  methods: {
    editLabelHandler() {
      this.editLabel = true;
      // let self = this;

      setTimeout(() => {
        window.$('#google-labels').select2({
          tags: true,
          tokenSeparators: [';', '\n', '\t']
        });

        window.$('#google-labels').on('select2:closing', function () {
          let $select2 = window.$(this);
          let $searchField = $select2.next().find('.select2-search__field');
          let inputValue = $searchField.val();

          if (inputValue && inputValue.length > 0) {
            $select2.append('<option value="' + inputValue + '" selected="selected">' + inputValue + '</option>');
            $searchField.val('');
            $select2.trigger('change');
          }
        });

        // window.$('#google-labels').on('change', () => {
        //   this.labelForm.labels = window.$("#google-labels").select2("val");
        // });

        window.$('#google-labels').val(this.amazonAd.labels.map(label => label.name)).change();
      }, 50);
    },
    saveLabels() {
      this.loading = true;
      this.loadingMessage = "Updating labels...";
      this.labelForm.labels = window.$("#google-labels").select2("val");

      this.axios.post('/labels/update', this.labelForm)
          .then((res) => {
            window.$('.select-labels').select2('destroy');
            window.$('.select-labels').off('change');

            this.editLabel = false;
            this.$store.dispatch('loadLabels')
                .then(res => {
                  this.labels = res.data.data;
                });
            this.amazonAd.labels = res.data.data;
            this.$emit('labelUpdated', {
              id: this.amazonAd.id,
              labels: this.amazonAd.labels
            })
          })
          .catch(() => {
          })
          .finally(() => {
            this.loading = false;
          });
    },
    loadAmazonAd(googleCampaignId) {
      this.axios.get('/google/campaign/' + googleCampaignId)
          .then(res => {
            this.amazonAd = res.data.data;
            this.nickNameForm.id = this.amazonAd.id;
            this.labelForm.amazon_ad_id = this.amazonAd.id;
          })
    },
    changeMassUrl() {
      this.$swal({
        title: 'Are you sure want to change mass url?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgba(253,23,31,0.7)',
        confirmButtonText: 'Yes, change it!',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.loadingMessage = "Updating URL...";
          this.axios.post('/google/change/mass-url', {
            id: this.campaign.id,
            amazon_profile: this.settings.amazon_profile_id,
            link: this.campaign.ad_link
          }).then((res) => {
            if (res.data.data) {
              this.campaign.target_url = res.data.data.target_url;
              this.campaign.tracking_url = res.data.data.tracking_url;
              this.campaign.ad_link = res.data.data.ad_link;
            }
          }).catch((err) => {
            this.allErrors = err.response.data.errors;
          }).finally(() => {
            this.loading = false;
          });
        }
      });
    },
    saveBidding(maxCpc) {
      this.biddingStrategyForm.max_cpc = +maxCpc;
      this.saveBiddingStrategy();
    },
    saveBiddingStrategyBtnHandler() {
      if (this.biddingStrategyForm.strategy === 'Manual CPC' && this.biddingStrategyForm.max_cpc_type === 'average') {
        this.$refs.changeBiddingStrategyModal.show(this.campaign)
      } else {
        this.saveBiddingStrategy();
      }
    },
    saveBiddingStrategy() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating bidding strategy...";

      if (this.biddingStrategyForm.max_cpc_type === 'current') {
        this.biddingStrategyForm.max_cpc = this.campaign.max_cpc;
      }

      this.axios.post('/google/change/bidding-strategy', this.biddingStrategyForm)
          .then(() => {
            this.campaign.bidding_strategy = this.biddingStrategyForm.strategy;
            this.editBiddingStrategy = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveAdLink() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating ad link...";

      this.axios.post('/google/change/ad-link', this.adLinkForm)
          .then((res) => {

            this.campaign.target_url = res.data.data.target_url;
            this.adLinkForm.adLink = res.data.data.target_url;
            this.editAdLink = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveNickname() {
      this.loading = true;

      this.axios.post('/change-nickname', this.nickNameForm)
          .then(() => {
            this.campaign.nickname = this.nickNameForm.name;
            this.editNickName = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveBudget() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating budget...";

      this.axios.post('/google/change/budget', this.newBudgetForm)
          .then(() => {

            this.campaign.budget = parseFloat(this.newBudgetForm.amount);
            this.newBudgetForm.amount = '';
            this.editBudget = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveLocation() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating location...";

      this.axios.post('/google/change/location', this.newLocationForm)
          .then(() => {
            this.campaign.criteria_id = this.newLocationForm.criteriaId;
            this.editLocation = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    saveMaxCpc() {
      this.allErrors = null;
      this.loading = true;
      this.loadingMessage = "Updating max cpc...";

      this.axios.post('/google/change/max-cpc', this.newMaxCpcForm)
          .then(() => {
            this.campaign.max_cpc = this.newMaxCpcForm.amount ? parseFloat(this.newMaxCpcForm.amount) : 0;
            this.newMaxCpcForm.amount = '';
            this.editMaxCpc = false;
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    show(campaignId) {
      this.editLabel = false;
      this.editBudget = false;
      this.editLocation = false;
      this.editMaxCpc = false;
      this.editAdLink = false;
      this.editBiddingStrategy = false;

      this.loading = true;
      this.loadingMessage = "Reaching out to Google...";

      this.campaign = null;
      this.amazonProduct = null;
      this.newBudgetForm.campaignId = campaignId;
      this.newMaxCpcForm.campaignId = campaignId;
      this.newLocationForm.campaignId = campaignId;
      this.biddingStrategyForm.campaignId = campaignId;
      this.adLinkForm.campaignId = campaignId;
      this.modal.show();

      this.labels = this.$store.state.labels;

      this.axios.get('/google/ad-details', {
        params: {
          campaignId: campaignId
        }
      }).then((res) => {
        this.loadAmazonAd(res.data.id);
        this.campaign = res.data;
        this.newLocationForm.criteriaId = this.campaign.criteria_id;
        this.biddingStrategyForm.strategy = this.campaign.bidding_strategy;
        this.adLinkForm.adLink = this.campaign.ad_link;
      }).catch((err) => {
        this.showFailMsg(err.response.data.message);
      }).finally(() => {
        this.loading = false;
      })
    },
    hide() {
      this.modal.hide();
    }
  }
}
</script>