<template>
  <div class="modal fade"
       id="change-bidding-strategy-modal"
       tabindex="-1" role="dialog"
       aria-labelledby="exampleModalLabel"
       aria-hidden="true"
       :data-modal-parent="'#'+id">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p class="font-weight-bold mb-4">If we don't have average CPC data for a specific keyword, what should we use for that keyword's Max CPC?</p>

          <div class="form-check mb-3">
            <input class="form-check-input"
                   type="radio"
                   name="gridRadios"
                   value="max"
                   :disabled="!this.campaign || !this.campaign.max_cpc"
                   v-model="form.type"
                   id="max">
            <label class="form-check-label"
                   for="max">
              Use current max <span v-if="this.campaign && this.campaign.max_cpc">( ${{ this.campaign.max_cpc }} )</span>
            </label>
          </div>

          <div class="form-check">
            <input class="form-check-input"
                   type="radio"
                   name="gridRadios"
                   id="manual"
                   v-model="form.type"
                   value="manual">
            <label class="form-check-label"
                   for="manual">
              Set Manually:

              <input type="text"
                     :disabled="form.type === 'max'"
                     v-model="form.manual_max" />
            </label>
          </div>
        </div><!--end modal-body-->
        <div class="modal-footer">
          <button type="button" class="btn btn-sm btn-dark" data-dismiss="modal">Cancel</button>
          <button type="button"
                  @click.prevent="save"
                  class="btn btn-sm btn-success"
                  :disabled="!isValid">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      modal: null,
      campaign: null,
      form: {
        type: 'max',
        manual_max: ''
      }
    }
  },
  computed: {
    isValid() {
      return !(this.form.type === 'manual' && (!this.form.manual_max || isNaN(this.form.manual_max)));
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById("change-bidding-strategy-modal"));

    window.$('#change-bidding-strategy-modal').on('show.bs.modal', function () {
      let modalParent = window.$(this).attr('data-modal-parent');
      window.$(modalParent).css('opacity', 0);
    });

    window.$('#change-bidding-strategy-modal').on('hidden.bs.modal', function () {
      let modalParent = window.$(this).attr('data-modal-parent');
      window.$(modalParent).css('opacity', 1);
    });
  },
  methods: {
    show(campaign) {
      this.campaign = campaign;
      this.form.manual_max = '';

      if (this.campaign.max_cpc) {
        this.form.type = 'max';
      } else {
        this.form.type = 'manual';
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    save() {
      let maxCpc = 0;

      if (this.form.type === 'max') {
        maxCpc = this.campaign.max_cpc;
      } else {
        maxCpc = this.form.manual_max;
      }

      this.$emit('saveBidding', maxCpc);
      this.hide();
    }
  }
}
</script>